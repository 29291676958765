@import url('https://fonts.googleapis.com/css2?family=Heebo:wght@100..900&family=Urbanist:ital,wght@0,100..900;1,100..900&display=swap');

body {
    --sb-track-color: #eff0f1;
    --sb-thumb-color: #318bec;
    --sb-size: 10px;
}

* {
    font-family: "Urbanist", sans-serif;
}

.help::-webkit-scrollbar {
    width: 10px
}

.help::-webkit-scrollbar-track {
    background: var(--sb-track-color);
    border-radius: 20px;
}

.help::-webkit-scrollbar-thumb {
    background: var(--sb-thumb-color);
    border-radius: 20px;

}

.mainChatArea::-webkit-scrollbar,
.mainChatAreaInner-drawer::-webkit-scrollbar,
.mainChatAreaInner::-webkit-scrollbar,
.listOuter::-webkit-scrollbar {
    width: 1px
}


@supports not selector(::-webkit-scrollbar) {
    body {
        scrollbar-color: var(--sb-thumb-color) var(--sb-track-color);
    }
}



.chatArea {

    position: relative;
    margin: auto;

}

.chatArea .defaultTitle {
    font-size: 32px;
    font-weight: 700;
    color: #292929;
    text-align: center;
}

@media screen and (max-width:1330px) {
    .chatArea .defaultTitle {
        margin-top: 47px;
    }
}

@media screen and (max-width:1330px) {
    .chatArea .defaultTitle {
        font-size: 24px;
        margin-bottom: 0;
    }
}

.chatArea .help {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin-top: 40px;
    flex-wrap: wrap;
    overflow-y: auto;
    height: 250px;

}

@media screen and (max-width:576px) {
    .chatArea .help {
        height: 350px;
        overflow-y: auto;
    }
}

.chatArea .help .item {
    width: 246px;
    border-radius: 14px;
    padding: 24px;
    border: 1px solid #DEDEE9;
    box-shadow: 0px 2px 5px #0D0A2C0F;
    transition: all .3s ease;
    position: relative;
    height: 220px;
    cursor: pointer;
}

@media screen and (max-width:576px) {
    .chatArea .help .item {
        height: 200px;
    }
}

.chatArea .help .item:hover {
    background-color: #318BEC;
    color: #FFFFFF;
    transition: all .3s ease;

}

.bgImage {
    position: absolute;
    inset: 0;
    display: grid;
    place-items: center;
}

.helpOuter {

    height: calc(100vh - 120px);
    overflow: hidden;
}

.chatBot .newChat {
    margin-bottom: 32px;
    font-size: 16px;
    display: flex;
    align-items: center;
    gap: 12px;
    font-weight: 700;
    color: #fff;
    background-color: #318BEC;
    padding: 16px 46px;
    border: 0;
    border-radius: 100px;
    cursor: pointer;
}

.chatBot {
    padding: 40px 20px;
}

@media screen and (max-width:600px) {
    .chatBot {
        padding: 10px 10px;
    }

    .chatBot .newChat {
        margin-bottom: 72px;
    }
}

.ost:not(:last-child) {
    border-right: 1px solid #E1EAFF;
}

.chatBot .logo {
    margin-bottom: 20px;
}

.indicator {
    box-shadow: none !important;
}

.chatBot .logOut {
    position: sticky;
    bottom: 40px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 14px;
    border-radius: 100px;
    box-shadow: 14px 17px 40px 4px #7090B014;
}

.chatBot .logOutBtn {
    cursor: pointer;
    display: flex;
    align-items: center;
}

.chatBot .logOut .item {
    display: flex;
    align-items: center;
    gap: 12px;
}

.chatBot .logOut .item .name {
    font-size: 14px;
    font-weight: 700;
    color: #292929;
}

.chatBot .logOut .userLogoutImage {
    width: 34px;
    aspect-ratio: 1;
    object-fit: cover;
    border-radius: 50%;
}

.img-data {
    width: 34px;
    aspect-ratio: 1;
    object-fit: cover;
    border-radius: 50%;
}

.chatBot .title {
    font-size: 16px !important;
    font-weight: 700 !important;
    color: #292929 !important;
}

.chatBot .btnOuter {
    padding-top: 24px;
    border-top: 1px solid #E9EDF7;
}



.chatBot .MuiListItem-root {
    color: #7D7D7D;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    width: fit-content;
}

.chatBot .listOuter {
    display: flex;
    flex-direction: column;
    gap: 20px;
    overflow-y: auto;

}

.chatBot .listItem {
    display: flex;
    list-style: none;
    padding: 10px;
    flex-direction: column;
    gap: 16px;
    padding-bottom: 20px;
    border-bottom: 1px solid #E9EDF7;
}

.chatSection {
    overflow-y: auto;
    max-width: 100%;

}


.inputFocus {
    -webkit-appearance: none;
    appearance: none;
    outline: none;
    transition: all 0.6s ease;
}

.inputFocus:focus {
    outline: none;
    border: "0"

}

.inputFocus:focus-within {
    outline: none;
    border: "0"

}




.searchQ {
    width: calc(100%);
    z-index: 4;
    padding-top: 10px;
    padding-bottom: 10px;
}


.modalCustom {
    position: relative;
    z-index: 4 !important;

}

@media screen and (min-width: 1900px) {
    .modalCustom .modalInner {
        right: 150.3px;
        top: 100px;
        left: "auto";
    }
}

@media screen and (min-width: 1500px) {
    .modalCustom .modalInner {
        right: 150.2px;
        top: 99.4px;
        left: auto;
    }
}

@media screen and (min-width: 1200px) {
    .modalCustom .modalInner {
        top: 99px;
        right: 167px;
        left: auto;
    }
}


@media screen and (min-width: 992px) {
    .modalCustom .modalInner {
        top: 99px;
        right: 167px;
        left: auto;
    }
}

@media screen and (max-width: 991px) {
    .modalCustom .modalInner {
        top: 204px;
        right: auto;
        left: 0px;
    }
}

@media screen and (max-width: 767px) {
    .modalCustom .modalInner {
        top: 212px;
        right: auto;
        left: 0px;
    }
}

@media screen and (max-width: 576px) {
    .modalCustom .modalInner {
        top: 199px;
        right: auto;
        left: 0px;
    }
}



.modalCustom .modalInner {
    background-color: #fff;
    position: absolute;
    padding: 24px;
    border-radius: 16px;
    max-width: 540px;

}

@media screen and (max-width: 1400px) {
    .modalCustom .modalInner {
        width: 460px;
    }
}



@media screen and (max-width: 733px) {
    .modalCustom .modalInner {
        width: 460px;
    }
}

@media screen and (max-width: 733px) {
    .modalCustom .modalInner {
        width: 460px;
    }
}

@media screen and (max-width: 576px) {
    .modalCustom .modalInner {
        width: 400px;
    }
}

@media screen and (max-width: 440px) {
    .modalCustom .modalInner {
        width: 277px;
    }
}

@media screen and (max-width: 991px) {
    .modalCustom .modalInner {
        margin-left: 1rem;
        margin-right: 1rem;

    }
}

.modalInner .items {
    gap: 20px;
    width: 100%;
}

@media screen and (max-width: 733px) {
    .modalInner .items {
        overflow-y: auto;
        max-height: 220px;
    }
}

.modalCustom .upper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
}

.modalCustom .upper .close {
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
}

.modalInner .title {
    font-size: 14px;
    font-weight: 600;
    color: #323232;
}

.modalInner .item {
    max-width: 236px;
    border: 1px solid #DEDEE9;
    border-radius: 15px;
    font-size: 16px;
    color: #292929;
    padding: 20px 16px;
    transform: all .3s ease;
    height: 120px;
    display: flex;
    align-items: start;
    justify-content: start;
}

@media screen and (max-width: 700px) {
    .modalInner .item {
        height: 100px;
        padding: 10px 10px;
    }
}

@media screen and (max-width: 1400px) {
    .modalInner .item {
        max-width: 196px;
    }
}

@media screen and (max-width: 733px) {
    .modalInner .item {
        max-width: 100%;
    }
}

.modalInner .item:hover {
    transform: all .3s ease;
    color: #fff;
    background-color: #318BEC;
    cursor: pointer;
}

.bottomContentOuter {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;

}

.bottomContent {

    width: 100%;
    z-index: 4;
    padding-top: 10px
}

.copyright {
    text-align: center;
    padding-bottom: 5px;
}

.drawer-copyright {
    text-align: center;
    padding-bottom: 5px;
}


.copyright p {
    margin: 0;
    font-size: 16px;
    font-weight: 500;
    color: #718096;
    margin-top: 24px;
}

.drawer-copyright p {
    margin: 0;
    font-size: 16px;
    font-weight: 500;
    color: #718096;
    margin-top: 15px;
}

.searchQ .textField {
    height: 62px;
    border-radius: 100px;
    width: 100%
}

.marginT {
    margin-top: 10px;
}

.searchQ .submitBtn {
    border: 0;
    text-align: center;
    background-color: #318BEC;
    padding: 15px 40px;
    border-radius: 100px;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    box-shadow: none;
    position: absolute;
    right: 6px;
    top: 6px;
}

@media screen and (max-width:768px) {
    .searchQ .submitBtn {
        padding: 15px 30px;
        border-radius: 100px;
    }

}

@media screen and (max-width:600px) {
    .searchQ .submitBtn {
        padding: 13px 16px;
        border-radius: 50%;
        right: 8px;
        top: 8px;
    }

}

.searchQ input:focus {
    outline: 0;
}

.searchQ input {
    padding: 19px 24px;
    border: 1px solid #C7C7C7;
    width: 100%;
    font-weight: 600;
    font-size: 16px;
    background-color: #ffffff;

}

.chatting .message {
    border: 1px solid #E2E8F0;
    padding: 22px;
    border-radius: 14px;
    display: inline-block;
    position: relative;
    z-index: 1;
    font-size: 16px;
    font-weight: 600;
    color: #585858;
    max-width: 470px;
    margin-left: 18px;
    min-width: 240px;

}

.chatting .message p {
    font-size: 16px;
    font-weight: 600;
    color: #585858;
}

.senderImageSize {
    width: 40px;
    aspect-ratio: 1;
    object-fit: cover;
    border-radius: 50%;
}

.chatItem .userImage {
    left: -19px;
    top: -23px;
}

.response .message {
    border: 1px solid #318BEC;
    background-color: #318BEC10;
    color: #585858;

}

.response {
    margin-left: 18px;
}

.chatting .senderName {
    font-size: 16px;
    margin-left: 45px;
    font-weight: 600;
    color: #718096;
}

.chatting .senderName .time {
    font-size: 12px;
    font-weight: 600;
    color: #7D7D7D;
    margin-left: 12px;
}

.chatting .userImage {
    position: absolute;

}

.loader-container {
    width: 94%;
    height: 20px;
    background-color: #f0f0f0;
    position: relative;
    margin-bottom: 5px;
    border-radius: 15px;
    text-align: center;
    z-index: 6;
    margin: auto;

}



/* Styles for the loader bar */
.loader-bar {
    /* position: absolute; */
    border-radius: 15px;
    height: 100%;
    width: 0;
    background-color: #dedfde;
    /* Green color for the loader */
    animation: progress 10s infinite;
    /* Animation for the loader progress */
}

/* Keyframes for the loader animation */
@keyframes progress {
    0% {
        width: 0;
    }

    50% {
        width: 50%;
    }

    100% {
        width: 100%;
    }
}



.resend-verify {
    cursor: pointer;
    color: #318BEC;
    font-weight: 800;
}

.business-note {

    color: red;
    padding: 11px 15px;
    font-size: 18px;
    font-weight: 100;

}


@media screen and (min-width: 580px) {
    .drawercustombefore {
        display: block;
        position: static;
        width: auto;
        background: none;
        height: auto;
        margin-bottom: 8px;
        transition: 5s ease-in;
    }
}




@keyframes example {
    from {
        width: 0%;
    }

    to {
        width: 100%;
    }
}

@media screen and (max-width: 575px) {
    .btn-wrapper {
        animation-name: btndelay;
        animation-duration: 2s;
    }

    .drawercustombefore {
        display: flex;
        flex-direction: row;
        position: absolute;
        bottom: -200%;
        left: "-100%";
        width: 0%;
        align-items: center;
        justify-content: center;
        background: white;
        height: 150px;
        transition: 5s ease-in;
        animation-name: example;
        animation-duration: 2s;
    }

    .drawercustom {
        display: flex;
        flex-direction: row;
        position: absolute;
        bottom: -200%;
        left: 0;
        width: 100%;
        align-items: center;
        justify-content: center;
        background: white;
        height: 150px;
        transition: 5s ease-in;
    }
}

@keyframes btndelay {
    0% {
        display: none;
    }

    30% {
        display: none;
    }

    100% {
        display: block;
        transition: 2s;
    }

}



.password-checker-before {
    font-family: Urbanist;
    font-size: 14px;
    font-weight: 500;
    line-height: 16.8px;
    color: #FF2323;

}

.password-checker-after {
    font-family: Urbanist;
    font-size: 14px;
    font-weight: 500;
    line-height: 16.8px;
    color: #43B077;

}

.css-1mm9d3u {
    max-width: "100%"
}

.modalInner2 {
    border-radius: 16px;
    background-color: #ffffff;
    padding-top: 16px;
    padding-bottom: 16px;
}

.recentSearch {
    margin-top: 16px;
}

.searchInner {
    display: flex;
    width: 100%;
    height: auto;

}

.searchtext {
    color: #292929;
    font-family: Urbanist;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    text-wrap: wrap;
}

.searchtextOuter {
    display: "flex";
    flex-direction: row;
}

.closebtn {
    text-align: right;
}


.customScrollbar::-webkit-scrollbar {
    width: 7px;
}

.customScrollbar::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}

.customScrollbar::-webkit-scrollbar-thumb {
    background: grey;
    border-radius: 10px;
}

.hoverChangeColor:hover svg path {
    fill: #ffffff;
}

.burgerMenu {
    border: none;
    background: transparent;
    padding: "12px 10px";
    display: block;
    margin-right: 7px;
}

@media screen and (min-width:1199px) {
    .burgerMenu {
        display: none;
    }

}

@media screen and (max-width:1199px) {
    .MuiDrawer-pape {
        z-index: 999;
        width: "100%"
    }
}


/*  */

.chatBot {
    min-height: 100vh;
    max-height: 100vh;
    max-width: 100vw;
    display: flex;
    gap: 24px;
    background-color: #FBFBFB;
}

.chatBot .outer-sidenav-chatbot {
    width: 290px;
    height: 100%;
    position: "fixed";
    left: 0;
    top: 0;
    padding: 0
}

.chatBot .outer-sidenav-chatbot .chatBotSidebar {
    width: 290px;
    box-shadow: 0 17px 40px 4px #7090B01C;
    border-radius: 20px;
    height: calc(100vh - 80px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #fff;
    padding: 24px;
}

.chatSectionOuter {
    width: calc(100vw - 290px);
    min-height: calc(100%);
    overflow: hidden;
    position: relative;
    margin: 0 auto;


}

.chatSectionInner {
    min-width: 100%;
    margin: 0 auto;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100%;

}

.helpSectionInner {
    width: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 60px;
}



.chatSectionOuter .backD {
    padding: 12px 16px;
    display: inline-flex;
    align-items: center;
    border: 0;
    inset: 0 auto auto 0;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0 17px 40px 4px #7090B01C;
    font-size: 16px;
    font-weight: 700;
    margin: 0;
    z-index: 3;
    color: #292929;
    text-transform: capitalize;
    position: absolute;
    left: 0;
    top: 15px;
}

@media screen and (max-width:1400px) {
    .chatSectionOuter .backD {
        font-size: 0;
        gap: 0px;
        padding: 5px 0px;
        border-radius: 100px;
    }
}


.mainChatArea {
    width: 100%;
}

.bottomContainer {
    width: 70%;
    margin: 0 auto;
    position: relative;

}

@media screen and (max-width:1100px) {
    .bottomContainer {
        width: 100%;
    }

}

.chatContainer {
    width: 95%;
    margin: 0 auto;

}

.commonSection {
    width: 65%;
    margin: 0 auto;
}

@media screen and (max-width:1100px) {
    .commonSection {
        width: 100%;
    }
}

@media screen and (max-width:1100px) {
    .chatContainer {
        width: 100%;

    }
}

@media screen and (max-width:1100px) {
    .chatSectionOuter {
        width: 100%;
    }
}

@media screen and (max-width:1000px) {
    .mainChatArea {
        width: 100%
    }
}

@media screen and (max-width:900px) {
    .outer-sidenav-chatbot {
        display: none;
    }
}

.mainChatAreaInner {
    max-height: calc(100vh - 220px);
    overflow-y: auto;
    width: 100%;
    padding-top: 100px;
}

@media screen and (max-width:600px) {
    .mainChatAreaInner {
        max-height: calc(100vh - 180px);
    }
}

.hover-shadow:hover {
    background-color: #29292930;
}

.hover-shadow {
    padding-top: 3px;
    padding-bottom: 1px;
    padding-left: 6px;
    padding-right: 6px;
    border-radius: 6px;

}

.main-wrapper {
    border: 1px solid #dfdfdf;
    flex-wrap: wrap;
    display: grid;

    grid-template-columns: repeat(2, 1fr);
    /* grid-template-columns: repeat(3, 1fr); */
    border-radius: 20px;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;
    height: 100%;
    gap: 15px 0px;
    width: 100%
}

@media (min-width:1400px) {
    .main-wrapper {
        display: grid;
        /* grid-template-columns: repeat(3, 1fr); */
    }
}

.item-name {
    font-size: 12px;
    display: flex;
    align-items: start;
    font-weight: 600;
    line-height: 20px;
    color: #707EAE;
    text-transform: capitalize;
    font-family: urbanist;
}

.item-value {
    color: #292929;
    font-family: Urbanist;
    font-size: 18px;
    font-weight: 700;
    line-height: 25px;

}

.item-dot {
    height: 12px !important;
    width: 12px;
    border-radius: 24px;
    display: block;
    margin-right: 5px;
    margin-top: 4px;
    text-align: top;

}


.innerItems {

    padding-left: 10px;
    padding-right: 10px;
    flex-grow: 1;
    flex-basis: 33.3%;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    position: relative;
    text-align: left;
}



.bdr-0 {
    border: none;
}

.main-wrapper-outer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%
}

.item-name {
    font-size: 12px;
    font-weight: 600;
    line-height: 20px;
    color: #707EAE;
    text-transform: capitalize;
    font-family: urbanist;
    display: flex;
    flex-wrap: wrap;
}

.innerItems-outer {
    display: flex;
    justify-content: center;
    flex-direction: column;
    position: relative;
    height: 100%;
    cursor: pointer;
    padding-inline: 20px;
}
@media screen and (max-width: 576px) {
    .innerItems-outer{
        padding-inline: 12px;
    }
}

/* drawer chat */
.overlay-drawer {
    height: 100%;
    width: 0;
    position: absolute;
    z-index: 1;
    right: 0;
    top: 0;
    background-color: #ffffff;
    overflow-x: hidden;
    overflow-y: hidden;
    transition: 0.5s;
    min-height: 100vh;
}

.overlay-drawer-inner {
    position: relative;
    height: 100%;

}

.sending-bar {
    position: absolute;
    bottom: 0;
    left: 0;

}

.sending-bar-inner {
    position: relative;
}

.bar-textarea {
    width: 100%;
    display: flex;
    gap: 10px;
    position: absolute;
    left: 0;
    bottom: 0;
}

.drawer-textField {
    height: 56px;
    border-radius: 100px;
    width: 100%;
    outline: 0;
    border: 1px solid #C7C7C7;
    padding: 20px;

}

.drawer-textField::placeholder {
    padding-left: 0px;
    font-family: Urbanist;
    font-size: 16px;
    font-weight: 600;
    line-height: 19.2px;
    text-align: left;
    color: #7D7D7D;
}

.drawer-submitBtn {
    border: 0;
    text-align: center;
    background-color: #318BEC;
    padding: 15px 36px;
    border-radius: 100px;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    box-shadow: none;
    position: absolute;
    right: 13px;
    top: 13px;
}

.textmsg {
    position: relative;
    /* height:100%; */
    display: flex;
}

.textmsg-outer {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
}

.textmsg-inner {
    display: flex;
    padding: 10px;
    position: relative;
}

.mainChatAreaInner-drawer {
    max-height: calc(100vh - 200px);
    overflow-y: auto;
    width: 100%;
    max-width: 100%;
    padding-top: 10px;
    padding-left: 20px;
    padding-right: 20px;
}

.copy-icon {
    position: absolute;
    right: 10px;
    bottom: 5px
}

.underline-imp {
    text-decoration: underline !important;
}

.clamp-add {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.needs-attention-yes {
    background-color: red;
    color: white;
    padding: 5px;
    border-radius: 4px;
    font-family: Urbanist;
    font-size: 16px;
    font-weight: 500;
    line-height: 19.2px;
    margin-top: 5px;
}

.needs-attention-no {
    background-color: green;
    color: white;
    padding: 5px;
    border-radius: 4px;
    font-family: Urbanist;
    font-size: 16px;
    font-weight: 500;
    line-height: 19.2px;
    margin-top: 5px;
}